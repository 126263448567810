
















import Vue from 'vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'index',
  methods: {
    ...mapMutations('globals', [
      'SET_FILTERDRAWER',
    ]),
    ...mapMutations('reboxes', ['CLEAR_REBOXES']),
  },
  components: {
    DefaultLayout: () => import('@/layouts/Default.vue'),
    ApplicationSwitcher: () => import('@/components/index/ApplicationSwitcher.vue'),
    FilterDrawer: () => import('@/components/ui/FilterDrawer.vue'),
    SettingsDrawer: () => import('@/components/index/SettingsDrawer.vue'),
    DisplayVersion: () => import('@/components/layouts/DisplayVersion.vue'),
  },

  created() {
    this.CLEAR_REBOXES();
    this.SET_FILTERDRAWER(false);
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'header.processes',
      icons: { icon: icons.noImage, action: actions.noAction },
    });
  },
  mounted() {
    const { filterIcons } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      drawerIcon: filterIcons.settings,
    });
  },
  beforeDestroy() {
    const { filterIcons } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      drawerIcon: filterIcons.noIcon,
    });
  },
});
